<template>
  <div class="admin-invoce-preview" v-if="!$route.query.showProjectInfo">
    <rz-request-state :state="state" />
    <div
      v-if="state.status.isLoaded"
    >
      <div>
        <InvoicePreviewActions
          :invoice="invoice"
          :adminInvoice="adminInvoice"
        />
        <div class="preview-box">
          <div class="admin-escape-theme">
            <div class="invoice">
              <InvoiceView
                :invoiceInfo="adminInvoice"
                :receiptInfo="{}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <QuickPreviewView
    v-if="previewProject && previewProject.key && $route.query.showProjectInfo"
    hideLeft="true"
    class="nofixed"
  >
    <template v-slot:content>
      <ProjectPreview
        :projectKey="previewProject.key"
      />
    </template>
  </QuickPreviewView>
</template>
<script>
import {
  ref,
  onMounted,
  watch,
  computed,
} from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { RequestState, RequestStateStatus } from '@/api/core';
import {
  InvoiceReport,
  ProjectList,
} from '@/store/modules/admin';

import ProjectPreview from '@/components/admin/ProjectPreview.vue';
import QuickPreviewView from '@/components/admin/QuickPreviewView.vue';
import InvoiceView from '@/components/InvoiceView.vue';
import InvoicePreviewActions from './InvoicePreviewActions.vue';

export default {
  props: {
    invoiceKey: String,
    info: Object,
  },

  components: {
    InvoiceView,
    InvoicePreviewActions,
    ProjectPreview,
    QuickPreviewView,
  },

  setup(props) {
    const store = useStore();
    const route = useRoute();
    const state = ref(new RequestState());
    const previewProject = ref({});
    const projectKey = ref(route.params.key);

    const fetchData = () => {
      ProjectList.fetchProject(props.info.project.key).then((response) => {
        previewProject.value = response;
      });
    };

    onMounted(() => {
      fetchData();
    });

    watch(() => route.params, () => {
      projectKey.value = route.params.key;
      fetchData();
    });

    const fetchAdminInvoice = () => {
      state.value.status = RequestStateStatus.Loading;
      InvoiceReport.fetchAdminInvoice(props.invoiceKey).then((invoiceResposne) => {
        store.commit('admin/updatePreviewAdminInvoice', { adminInvoice: invoiceResposne });
      }).then(() => {
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };

    const fetchInvoice = () => {
      state.value.status = RequestStateStatus.Loading;
      InvoiceReport.fetchInvoice(props.invoiceKey).then((invoiceResposne) => {
        store.commit('admin/updatePreviewInvoice', { invoice: invoiceResposne });
        fetchAdminInvoice();
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };

    watch(() => props.invoiceKey, () => {
      fetchInvoice();
      fetchData();
    });

    onMounted(() => {
      fetchInvoice();
    });

    return {
      state,
      invoice: computed(() => store.state.admin.previewInvoice),
      adminInvoice: computed(() => store.state.admin.previewAdminInvoice),
      previewProject,
    };
  },
};
</script>
