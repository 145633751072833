<template>
  <div class="transaction">
    <h3>Unattached Transactions</h3>
    <div class="transaction__table" v-if="transactionInfo">
      <ul class="table-header">
        <li class="table-header__amount">Amount</li>
        <li class="table-header__date">Date</li>
        <li class="table-header__payment">Notes</li>
        <li class="table-header__transaction-id">Transaction ID</li>
        <li></li>
      </ul>
      <template v-for="(transaction, index) in transactionInfo" :key="index">
        <ul class="table-body table-body__wrapper"
          v-if="transaction">
          <li
            class="table-body__amount"
          >{{ formatCurrency(transaction.amount, transaction.currency) }}</li>
          <li
            class="table-body__date"
          >{{$filters.formatDate(transaction.transaction_date)}}</li>
          <li class="table-body__payment">
            <div>{{transaction.notes && transaction.notes}}</div>
            <div v-if="transaction.private_notes" class="table-body__pri-notes">
              {{`Private notes - ${transaction.private_notes}`}}
            </div>
            <div class="table-body__notes">
              {{!(transaction.notes || transaction.private_notes)
              ? transaction.kind.split('_').join(' ')
              : ''}}
            </div>
          </li>
          <li
            class="table-body__transaction-id"
          >
            <span v-if="transaction.status === 'cancelled'">{{transaction.status}}</span>
            {{ transaction.key }}
          </li>
          <li>
            <button @click="attachTransaction(transaction.key)"  aria-label="attach"
              class="attach-btn">Attach</button>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.transaction {
  max-width: size(841);
  margin-top: size(55);
  h3 {
    @include font-styles($type: 'display', $size: 'sm', $weight: 'semibold');
    margin-bottom: size(15);
  }
  &__table {
    padding: size(40) size(25) size(35);
    background: var(--global--white--Color);
    border-radius: size(10);
    .table {
      &-header,
      &-body {
        display: flex;
        &__amount {
          width: size(100);
          max-width: size(100);
        }
        &__date {
          width: size(130);
          max-width: size(130);
        }
        &__payment {
          width: size(220);
          max-width: size(220);
        }
        &__notes {
          text-transform: uppercase;
        }
        &__pri-notes {
          color: var(--global--error--BackgroundColor);
          margin-top: size(15);
        }
        &__transaction-id {
          width: size(250);
          max-width: size(250);
          span {
            color: var(--global--error--BackgroundColor);
            font-size: size(12);
            line-height: size(14);
            text-transform: capitalize;
          }
        }
        &__icons {
          width: size(110);
          max-width: size(110);
        }
        svg {
          height: size(12);
          width: size(12);
        }
        li {
          word-wrap:break-word;
          white-space: normal;
          &:not(:first-of-type) {
            padding-left: size(30);
          }
          &:nth-last-child(1) {
            padding-left: size(0);
          }
          &:nth-last-child(2) {
            padding-right: size(30);
          }
           &:last-child {
            width: size(86);
          }
        }
      }
      &-header {
        li {
          @include font-styles($type: 'text', $size: 'md', $weight: 'normal');
          color: var(--global--link--dark--Color);
          text-align: left;
          padding-bottom: size(20);
          border-bottom: size(1) solid
            var(--global--line--table--BackgroundColor);
        }
      }
      &-body {
        &__amount {
          @include font-styles($type: 'text', $size: 'lg', $weight: 'bold');
        }
        &__date {
          @include font-styles(
            $type: 'label',
            $size: 'lg',
            $weight: 'semibold'
          );
        }
        &__payment {
          text-transform: capitalize;
          span {
            padding-left: size(10);
          }
          svg {
            fill: var(--global--success--BackgroundColor);
          }
        }
        &__icons {
          button {
            cursor: pointer;
            outline: none;
            position: relative;
            padding-left: size(25);
            .tool-tip {
              display: none;
              position: absolute;
              top: size(25);
              left: size(20);
              @include font-styles($type: 'label', $size: 'md', $weight: 'normal');
              padding: size(5);
              color: var(--global--success--BackgroundColor);
              min-width: max-content;
              border-radius: size(3);
              box-shadow: size(0) size(1) size(4) rgba(0, 0, 0, 0.15);
              z-index: 1;
              background: var(--global--white--Color);
              &.show {
                display: block;
              }
            }
            &:hover {
              svg {
                fill: var(--global--link--Color);
              }
              }
            &.mail-sent {
              svg {
                fill: var(--global--success--BackgroundColor);
              }
            }
          }
          a {
            padding-left: size(0);
          }
          }
          &__icons {
            svg {
              fill: var(--global--main--Color);
            }
          }
          button.mail-sent {
            &:hover {
              .tool-tip {
                display: block;
                &.show {
                  display: block;
                }
                // &.hide {
                //   display: none;
                // }
            }
          }
        }
        li {
          padding-bottom: size(16);
          padding-top: size(20);
          border-bottom: size(1) solid
            var(--global--line--table--BackgroundColor);
        }
        .attach-btn {
          @include font-styles(
            $type: 'label',
            $size: 'sm',
            $weight: 'regular'
          );
          background: var(--global--common-link--Color);
          padding: size(8) size(10);
          color: var(--global--white--Color);
          border-radius: size(5);
          border: size(1) solid var(--global--common-link--Color);
          display: inline-block;
          cursor: pointer;
        }
        &__wrapper {
          color: var(--global--main--Color);
        }
      }
    }
  }
}
</style>

<script>
import { currencyMethodsMixin } from '@/mixin/currency';
import { InvoiceReport } from '@/store/modules/admin';
import {
  ref,
} from 'vue';
import { useStore } from 'vuex';
import { RequestState } from '@/api/core';

export default {
  name: 'UnAttachedTransaction',
  mixins: [currencyMethodsMixin],
  props: {
    transactionInfo: {
      type: Object,
    },
    invoiceKey: {
      type: String,
    },
  },
  setup(props) {
    const store = useStore();
    const state = ref(new RequestState());

    const attachTransaction = (key) => {
      const payload = {
        key,
        invoices: [props.invoiceKey],
        action: 'attach-invoice',
        show_invoice_response: true,
      };
      InvoiceReport.attachTransaction(key, payload).then((resp) => {
        if (resp) {
          store.commit('admin/updatePreviewAdminInvoice', { adminInvoice: resp.data.data });
        }
      });
    };

    return {
      state,
      attachTransaction,
    };
  },
};
</script>
